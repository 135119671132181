import React, { useContext } from "react";

import { Context } from "@reactivated";

import { gettext } from "@thelabnyc/thelabui/src/utils/i18n";

import { FieldWrapper } from "../FormField";

import styles from "./Form.module.scss";

export const Form = (props: {
    email: string;
    setEmail: (email: string) => void;
    error: string | null;
    onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
    isSubmitting: boolean;
}) => {
    const context = useContext(Context);
    return (
        <div className={styles.container}>
            <h1 className={styles.heading}>
                {gettext("Unsubscribe from our emails", context)}
            </h1>
            <p className={styles.subheading}>
                {gettext(
                    "If you'd rather not get offers and news, just enter your email below. You're welcome back anytime.",
                    context,
                )}
            </p>
            <form className={styles.form} onSubmit={props.onSubmit}>
                <FieldWrapper
                    label={gettext("Email Address", context)}
                    error={props.error}
                    wrapperClassName={styles.emailField}
                >
                    <input
                        type="email"
                        name="Email"
                        placeholder={gettext("Email", context)}
                        value={props.email}
                        onChange={(e) => {
                            props.setEmail(e.currentTarget.value);
                        }}
                        disabled={props.isSubmitting}
                    />
                </FieldWrapper>
                <button
                    type="submit"
                    className={styles.submit}
                    disabled={props.isSubmitting}
                >
                    {gettext("Submit", context)}
                </button>
            </form>
        </div>
    );
};
