import React, { useContext } from "react";

import { Context } from "@reactivated";

import { gettext } from "@thelabnyc/thelabui/src/utils/i18n";

import { RLID, isoRLID } from "../../utils/models";

import styles from "./index.module.scss";

export const OrderNowButton = (props: {
    countryRootURL: string | null;
    rlid: RLID | number;
    hasOnlineOrdering: boolean;
}) => {
    const context = useContext(Context);
    const rlid = RLID.is(props.rlid) ? isoRLID.unwrap(props.rlid) : props.rlid;
    if (!props.hasOnlineOrdering) {
        return null;
    }
    const countryRootURL = props.countryRootURL || undefined;
    const orderURL = new URL(`/order?rlid=${rlid}`, countryRootURL).href;
    return (
        <a className={styles.button} href={orderURL}>
            {gettext("Order Now", context)}
        </a>
    );
};
