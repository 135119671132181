import { Libraries, useJsApiLoader } from "@react-google-maps/api";
import React, { useContext, useState } from "react";

import { Context } from "@reactivated";

import { gettext } from "@thelabnyc/thelabui/src/utils/i18n";

import {
    useNearbyRetailLocations,
    useUserLocation,
} from "../../hooks/location";
import {
    LatLng,
    RLID,
    isoLatitude,
    isoLongitude,
    isoMiles,
} from "../../utils/models";
import { LocationList } from "./LocationList";
import { MarkerMap } from "./MarkerMap";
import { UpdateLocationForm } from "./UpdateLocationForm";

import styles from "./index.module.scss";

const GOOGLE_LIBRARIES: Libraries = ["places", "geometry"];

// Default to Orlando, FL (until we get the user's actual location).
const DEFAULT_LOCATION: LatLng = {
    lat: isoLatitude.wrap(28.54),
    lng: isoLongitude.wrap(-81.38),
};
const DEFAULT_RADIUS = isoMiles.wrap(200);

export const LocatorMap = (props: { googleMapsApiKey: string }) => {
    const context = useContext(Context);
    useJsApiLoader({
        id: "google-map-script",
        googleMapsApiKey: props.googleMapsApiKey,
        libraries: GOOGLE_LIBRARIES,
    });
    const { userLocation, setEnteredLocation } = useUserLocation();
    const [focusedLocationID, setFocusedLocationID] = useState<RLID | null>(
        null,
    );
    const queryResp = useNearbyRetailLocations(userLocation);
    const locations = queryResp?.locations || [];

    return (
        <div className={styles.container}>
            <div className={styles.column1}>
                <UpdateLocationForm onLocationUpdate={setEnteredLocation} />
                {locations.length === 0 && (
                    <p className={styles.locationError}>
                        {gettext(
                            "Please enter a valid city, state, or ZIP code.",
                            context,
                        )}
                    </p>
                )}
                <LocationList
                    locations={locations}
                    onSelect={setFocusedLocationID}
                />
            </div>
            <div className={styles.column2}>
                <MarkerMap
                    locations={locations}
                    focusedLocationID={focusedLocationID}
                    setFocusedLocationID={setFocusedLocationID}
                />
            </div>
        </div>
    );
};
