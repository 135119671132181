import { ThelabuiContextProcessorsTranslationProcessor as TranslationProcessor } from "@reactivated";

import { gettext } from "@thelabnyc/thelabui/src/utils/i18n";

type ConditionalField =
    | "visitLocation"
    | "visitDate"
    | "visitTime"
    | "orderNumber";

type ExtraFields = {
    [name in ConditionalField]?: boolean;
};

interface Topic {
    name: string;
    extraFields: ExtraFields;
}

interface Category {
    name: string;
    topics: Topic[];
}

const NO_EXTRA_FIELDS: ExtraFields = {};
const ALL_EXTRA_FIELDS: ExtraFields = {
    visitLocation: true,
    visitDate: true,
    visitTime: true,
    orderNumber: true,
};

const getTopicCategories = (context: TranslationProcessor): Category[] => {
    return [
        {
            name: gettext("My Red Lobster Rewards™", context),
            topics: [
                {
                    name: gettext("Help With Adding Points", context),
                    extraFields: NO_EXTRA_FIELDS,
                },
                {
                    name: gettext("Help With Using Rewards", context),
                    extraFields: NO_EXTRA_FIELDS,
                },
                {
                    name: gettext("Question About My Points/rewards", context),
                    extraFields: NO_EXTRA_FIELDS,
                },
                {
                    name: gettext("Help With My Profile/sign In", context),
                    extraFields: NO_EXTRA_FIELDS,
                },
                {
                    name: gettext("Other", context),
                    extraFields: NO_EXTRA_FIELDS,
                },
            ],
        },
        {
            name: gettext("Praise", context),
            topics: [
                {
                    name: gettext("About Dining, To Go, Or Delivery", context),
                    extraFields: ALL_EXTRA_FIELDS,
                },
                {
                    name: gettext("Other", context),
                    extraFields: NO_EXTRA_FIELDS,
                },
            ],
        },
        {
            name: gettext("Suggestion", context),
            topics: [
                {
                    name: gettext("New Location", context),
                    extraFields: NO_EXTRA_FIELDS,
                },
                {
                    name: gettext("Menu Idea", context),
                    extraFields: NO_EXTRA_FIELDS,
                },
                {
                    name: gettext("Other", context),
                    extraFields: NO_EXTRA_FIELDS,
                },
            ],
        },
        {
            name: gettext("Question", context),
            topics: [
                {
                    name: gettext("Location Information", context),
                    extraFields: NO_EXTRA_FIELDS,
                },
                {
                    name: gettext("Menu Items/Events", context),
                    extraFields: {
                        visitLocation: true,
                    },
                },
                {
                    name: gettext("Allergen And Nutrition", context),
                    extraFields: {
                        visitLocation: true,
                    },
                },
                {
                    name: gettext("Donations", context),
                    extraFields: NO_EXTRA_FIELDS,
                },
                {
                    name: gettext("Gift Cards", context),
                    extraFields: NO_EXTRA_FIELDS,
                },
                {
                    name: gettext("Employment/Human Resources", context),
                    extraFields: NO_EXTRA_FIELDS,
                },
                {
                    name: gettext("Fresh Catch News", context),
                    extraFields: NO_EXTRA_FIELDS,
                },
                {
                    name: gettext("Other", context),
                    extraFields: NO_EXTRA_FIELDS,
                },
            ],
        },
        {
            name: gettext("Issue or Concern?", context),
            topics: [
                {
                    name: gettext(
                        "About Dining In, To Go, Or Delivery",
                        context,
                    ),
                    extraFields: ALL_EXTRA_FIELDS,
                },
                {
                    name: gettext("Other", context),
                    extraFields: NO_EXTRA_FIELDS,
                },
            ],
        },
    ];
};

export const getCategoryChoices = (context: TranslationProcessor): string[] => {
    const cats = getTopicCategories(context);
    return [""].concat(cats.map((cat) => cat.name));
};

export const getTopicChoices = (
    context: TranslationProcessor,
    category: string | null,
): string[] => {
    const cats = getTopicCategories(context);
    const cat = cats.find((cat) => cat.name === category);
    const topics = cat ? cat.topics.map((topic) => topic.name) : [];
    return [""].concat(topics);
};

export const getExtraFields = (
    context: TranslationProcessor,
    categoryName: string | null,
    topicName: string | null,
): ExtraFields => {
    const cats = getTopicCategories(context);
    const cat = cats.find((cat) => cat.name === categoryName);
    if (!cat) {
        return NO_EXTRA_FIELDS;
    }
    const topic = cat.topics.find((t) => t.name === topicName);
    if (!topic) {
        return NO_EXTRA_FIELDS;
    }
    return topic.extraFields;
};
