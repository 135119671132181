import React from "react";

import { Toolbox as Value } from "@reactivated";

import { ScrollNavMenuItemBlock } from "@thelabnyc/thelabui/src/components/ScrollNavMenuBlock";
import {
    StreamField,
    StreamFieldBlockMap,
} from "@thelabnyc/thelabui/src/components/StreamField";
import { TableInContainerBlock } from "@thelabnyc/thelabui/src/components/TableInContainerBlock";

import { AccordionFeatureBlock } from "../AccordionFeature";
import { AlertBlock } from "../Alert";
import { AlignedRichTextBlock } from "../AlignedRichTextBlock";
import { ArticlePreviewGridBlock } from "../ArticlePreviewGridBlock";
import { ContentGridBlock } from "../ContentGrid";
import { CouponBlock } from "../Coupon";
import { CustomBackgroundImageWrapper } from "../CustomBackgroundImageWrapper";
import { FAQsBlock } from "../FAQsBlock";
import { FeatureImageBlock } from "../FeatureImage";
import { FreshCatchFeatureBlock } from "../FreshCatchFeature";
import { GiftCardFeatureBlock } from "../GiftCardsFeature";
import { HeadingBlock } from "../HeadingBlock";
import { HeroBlock } from "../HeroBlock";
import { IFrameBlock } from "../IFrameBlock";
import { ImageLinkBlock } from "../ImageLink";
import { LinkRowBlock } from "../LinkRow";
import { MediaGridBlock } from "../MediaGridBlock";
import { SitemapBlock } from "../SitemapBlock";
import { SocialGridBlock } from "../SocialGridBlock";
import { TabbedFeaturesBlock } from "../TabbedFeatures";
import { TieredNavBlock } from "../TieredNavBlock";
import { TimelineBlock } from "../TimelineBlock";
import { TwoColumnFeatureBlock } from "../TwoColumnFeature";
import { TwoColumnHeroBlock } from "../TwoColumnHero";
import { UnsubscribeBlock } from "../UnsubscribeBlock";
import { VideoFeatureBlock } from "../VideoFeatureBlock";

interface Props {
    value: Value;
}

const components: StreamFieldBlockMap<Value> = {
    accordion_feature: AccordionFeatureBlock,
    alert: AlertBlock,
    artf: AlignedRichTextBlock,
    article_preview_grid: ArticlePreviewGridBlock,
    content_grid: ContentGridBlock,
    coupon: CouponBlock,
    custom_background_image_wrapper: CustomBackgroundImageWrapper,
    faqs: FAQsBlock,
    feature_image: FeatureImageBlock,
    fresh_catch_feature: FreshCatchFeatureBlock,
    gift_cards_feature: GiftCardFeatureBlock,
    heading: HeadingBlock,
    hero: HeroBlock,
    iframe: IFrameBlock,
    image_link: ImageLinkBlock,
    link_row: LinkRowBlock,
    media_grid: MediaGridBlock,
    scroll_nav_menu_item: ScrollNavMenuItemBlock,
    sitemap: SitemapBlock,
    social_grid: SocialGridBlock,
    tabbed_features: TabbedFeaturesBlock,
    table: TableInContainerBlock,
    tiered_nav: TieredNavBlock,
    timeline: TimelineBlock,
    two_column_feature: TwoColumnFeatureBlock,
    two_column_hero: TwoColumnHeroBlock,
    unsubscribe: UnsubscribeBlock,
    video_feature: VideoFeatureBlock,
};

export const Toolbox = (props: Props) => (
    <StreamField components={components} value={props.value} />
);
