import React, { useContext } from "react";

import { Context } from "@reactivated";

import { gettext } from "@thelabnyc/thelabui/src/utils/i18n";

import { Location } from "../../utils/models";
import { APIAddress } from "../Address";
import { FieldWrapper } from "../FormField";

export const LocationSelectInlineSearch = (props: {
    searchText: string;
    selectedLocation: Location | null;
    setSearchText: (searchText: string) => void;
    onSearch: () => void;
    onChangeSelection: () => void;
}) => {
    const context = useContext(Context);
    return (
        <FieldWrapper
            label={gettext("You're writing about the Red Lobster at:", context)}
        >
            {props.selectedLocation ? (
                <>
                    <APIAddress location={props.selectedLocation} />
                    <input
                        type="button"
                        value={gettext("CHANGE", context)}
                        onClick={(event) => {
                            event.preventDefault();
                            props.onChangeSelection();
                        }}
                    />
                </>
            ) : (
                <>
                    <input
                        type="text"
                        value={props.searchText}
                        onChange={(event) => {
                            props.setSearchText(event.currentTarget.value);
                        }}
                        onKeyPress={(event) => {
                            // If the user presses ENTER while this field is focused, we
                            // need to stop propagation to prevent the entire contact
                            // form from submitting.
                            if (event.key === "Enter") {
                                event.preventDefault();
                                event.stopPropagation();
                                props.onSearch();
                            }
                        }}
                    />
                    <input
                        type="button"
                        value={gettext("SEARCH", context)}
                        onClick={(event) => {
                            event.preventDefault();
                            props.onSearch();
                        }}
                    />
                </>
            )}
        </FieldWrapper>
    );
};
