import React from "react";

import { ImageChooserBlock, FeatureImageBlock as Value } from "@reactivated";

import { PageContentContainer } from "../PageContentContainer";

import styles from "./index.module.scss";

interface Props {
    value: Value;
}

export const FeatureImageBlock = (props: Props) => {
    if (!props.value.image || !props.value.width) {
        return null;
    }
    const renderImage = (width: string, image: ImageChooserBlock) => {
        switch (width) {
            case "centered": {
                return (
                    <PageContentContainer>
                        <img className={styles.image} src={image.url} />
                    </PageContentContainer>
                );
            }
            default:
                return <img className={styles.image} src={image.url} />;
        }
    };

    return (
        <section>{renderImage(props.value.width, props.value.image)}</section>
    );
};
