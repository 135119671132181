import React from "react";

import {
    GiftCardsFeatureBlock as Value,
    _GiftCardCTA,
    _GiftCardFeature,
} from "@reactivated";

import { ImageChooserBlock } from "@thelabnyc/thelabui/src/components/ImageChooserBlock";
import { RichTextBlock } from "@thelabnyc/thelabui/src/components/RichText";

import styles from "./index.module.scss";

interface Props {
    value: Value;
}

interface IGiftCardFeature {
    value: _GiftCardFeature;
}

interface IGiftCardCTA {
    value: _GiftCardCTA;
}

export const GiftCardFeature = (props: IGiftCardFeature) => {
    if (!props.value.content) {
        return null;
    }
    return (
        <div className={styles.cardFeature}>
            <RichTextBlock className="rich-text" value={props.value.content} />
            {props.value.image && (
                <ImageChooserBlock value={props.value.image} />
            )}
        </div>
    );
};

export const GiftCardCTA = (props: IGiftCardCTA) => {
    if (!props.value.cta?.url) {
        return null;
    }
    return (
        <div className={styles.cardCTA}>
            <p className={styles.ctaHeading}>{props.value.heading}</p>
            <a className="button" href={props.value.cta.url}>
                {props.value.cta.display_name}
            </a>
        </div>
    );
};

export const GiftCardFeatureBlock = (props: Props) => {
    if (!props.value.card_features || !props.value.ctas) {
        return null;
    }
    return (
        <section>
            <div className={styles.backgroundImage}>
                <div className={styles.featureWrapper}>
                    {props.value.card_features.map((item, i) => {
                        return <GiftCardFeature key={i} value={item} />;
                    })}
                </div>
            </div>
            <div className={styles.cardCTAWrapper}>
                {props.value.ctas.map((item, i) => {
                    return <GiftCardCTA key={i} value={item} />;
                })}
            </div>
        </section>
    );
};
