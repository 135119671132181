import React, { useContext } from "react";

import {
    CSRFToken,
    Context,
    RichTextField as RichTextFieldValue,
    templates,
    useForm,
} from "@reactivated";

import { RichText } from "@thelabnyc/thelabui/src/components/RichText";
import { gettext } from "@thelabnyc/thelabui/src/utils/i18n";

import { FormField } from "../FormField";
import { FooterLinks } from "./FooterLinks";
import { Legal } from "./Legal";
import { SpecialOccasions } from "./SpecialOccasions";

import styles from "./index.module.scss";

type Form = templates.EmailSignupPageTemplate["form"];
type OccasionFormSet = templates.EmailSignupPageTemplate["occasion_formset"];

type Page = {
    id: number;
    url: string;
    title: string;
};

export const EmailSignupForm = (props: {
    form: Form;
    occasionFormSet: OccasionFormSet;
    termsPage: Page;
    privacyPage: Page;
    faqPage: Page;
    unsubscribePage: Page;
    consentText: string;
    postConsentText: RichTextFieldValue;
    footerText: RichTextFieldValue;
}) => {
    const context = useContext(Context);
    const form = useForm({
        form: props.form,
    });
    return (
        <section>
            <p>*Required</p>
            <form method="post" className={styles.form}>
                <CSRFToken />

                {form.nonFieldErrors?.map((error, index) => (
                    <div key={index}>{error}</div>
                ))}

                <FormField field={form.fields.first_name} />
                <FormField field={form.fields.last_name} />
                <FormField field={form.fields.email} />
                <FormField field={form.fields.postcode} />

                <fieldset>
                    <legend className={styles.labelText}>Birthday</legend>
                    <div className={styles.birthdayFields}>
                        <FormField
                            wrapperClassName={styles.dropdown}
                            field={form.fields.birthday_month}
                            hideLabel={true}
                        />
                        <FormField
                            field={form.fields.birthday_day}
                            hideLabel={true}
                        />
                    </div>
                    <p>(For your birthday coupon)</p>
                </fieldset>

                <SpecialOccasions occasionFormSet={props.occasionFormSet} />

                <Legal
                    className={styles.legalWrapper}
                    postConsentText={props.postConsentText}
                >
                    <FormField
                        wrapperClassName={styles.legalFieldWrapper}
                        field={form.fields.terms}
                        label={
                            <>
                                I have read and accept the{" "}
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href={props.termsPage.url}
                                >
                                    TERMS AND CONDITIONS
                                </a>{" "}
                                and{" "}
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href={props.privacyPage.url}
                                >
                                    PRIVACY NOTICE
                                </a>
                                .
                            </>
                        }
                    />
                    <FormField
                        field={form.fields.consent}
                        label={props.consentText}
                    />
                </Legal>

                <div className={styles.actions}>
                    <FormField field={form.fields.captcha} hideLabel={true} />

                    <button type="submit" className={styles.send}>
                        {gettext("Sign Me Up", context)}
                    </button>
                </div>
            </form>

            <FooterLinks
                faqPage={props.faqPage}
                unsubscribePage={props.unsubscribePage}
            />

            <RichText value={props.footerText} />
        </section>
    );
};
