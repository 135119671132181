import React, { useContext } from "react";

import { Context } from "@reactivated";

import { gettext } from "@thelabnyc/thelabui/src/utils/i18n";

import { Location, RLID, isoRLID } from "../../utils/models";
import { APIAddress } from "../Address";
import { FieldWrapper } from "../FormField";
import { Modal, ModalRoot } from "../Modal";

import styles from "./LocationSelectModal.module.scss";

interface IProps {
    searchText: string;
    locations: Location[];
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    setSearchText: (searchText: string) => void;
    onSearch: () => void;
    onSelect: (rlid: RLID) => void;
    onSeeMore: () => void;
}

export const LocationSelectModal = (props: IProps) => {
    const context = useContext(Context);
    return (
        <ModalRoot open={props.isOpen} onOpenChange={props.setIsOpen}>
            <Modal contentLabel={gettext("Choose Your Restaurant", context)}>
                <div className={styles.modalContent}>
                    <h2>{gettext("Choose Your Restaurant", context)}</h2>
                    <form
                        onSubmit={(event) => {
                            event.preventDefault();
                            props.onSearch();
                        }}
                    >
                        <FieldWrapper
                            label={gettext(
                                "Please select the restaurant where you had your experience or search again",
                                context,
                            )}
                        >
                            <input
                                type="text"
                                value={props.searchText}
                                onChange={(event) => {
                                    props.setSearchText(
                                        event.currentTarget.value,
                                    );
                                }}
                            />
                            <input
                                type="submit"
                                value={gettext("SEARCH AGAIN", context)}
                            />
                        </FieldWrapper>
                    </form>

                    <hr />

                    <p>
                        {gettext("Showing %d results.", context).replace(
                            "%d",
                            `${props.locations.length}`,
                        )}
                    </p>

                    <div className={styles.resultGrid}>
                        {props.locations.map((location) => (
                            <button
                                key={`${isoRLID.unwrap(location.rlid)}`}
                                onClick={() => {
                                    props.onSelect(location.rlid);
                                }}
                            >
                                <APIAddress location={location} />
                            </button>
                        ))}
                    </div>
                </div>
            </Modal>
        </ModalRoot>
    );
};
