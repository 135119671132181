import React, { useContext, useState } from "react";

import { CSRFToken, Context, templates, useForm } from "@reactivated";

import { gettext } from "@thelabnyc/thelabui/src/utils/i18n";

import { FormField } from "../FormField";
import { LocationSelect } from "./LocationSelect";
import { getCategoryChoices, getExtraFields, getTopicChoices } from "./topics";

import styles from "./index.module.scss";

type ContactUsForm = templates.ContactUsPageTemplate["form"];

export const ContactUsForm = (props: { form: ContactUsForm }) => {
    const context = useContext(Context);
    const form = useForm({
        form: props.form,
    });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const extraFields = getExtraFields(
        context,
        form.values.question_category,
        form.values.topic,
    );
    return (
        <section className={styles.container}>
            <h2 className={styles.heading}>
                {gettext("Send Us a Message", context)}
            </h2>
            <p className={styles.subheading}>
                {gettext("All fields are required", context)}
            </p>
            <form
                method="post"
                className={styles.form}
                onSubmit={(event) => {
                    // Prevent double submits
                    if (isSubmitting) {
                        event.preventDefault();
                    } else {
                        setIsSubmitting(true);
                    }
                }}
            >
                <CSRFToken />

                {form.nonFieldErrors?.map((error, index) => (
                    <div key={index}>{error}</div>
                ))}

                <FormField
                    wrapperClassName={styles.questionCategory}
                    field={form.fields.question_category}
                    choices={getCategoryChoices(context)}
                />
                <FormField
                    wrapperClassName={styles.topic}
                    field={form.fields.topic}
                    choices={getTopicChoices(
                        context,
                        form.values.question_category,
                    )}
                />

                {extraFields.visitLocation && (
                    <div className={styles.visitLocation}>
                        <LocationSelect
                            formField={form.fields.location_rlid}
                            initialValue={form.values.location_rlid}
                        />
                    </div>
                )}

                {extraFields.visitDate && (
                    <FormField
                        wrapperClassName={styles.visitDate}
                        field={form.fields.visit_date}
                    />
                )}
                {extraFields.visitTime && (
                    <FormField
                        wrapperClassName={styles.visitTime}
                        field={form.fields.visit_time}
                    />
                )}
                {extraFields.orderNumber && (
                    <FormField
                        wrapperClassName={styles.orderNumber}
                        field={form.fields.order_number}
                    />
                )}
                <FormField
                    wrapperClassName={styles.firstName}
                    field={form.fields.first_name}
                />
                <FormField
                    wrapperClassName={styles.lastName}
                    field={form.fields.last_name}
                />
                <FormField
                    wrapperClassName={styles.email}
                    field={form.fields.email}
                />
                <FormField
                    wrapperClassName={styles.phone}
                    field={form.fields.phone}
                />

                <div className={styles.sectionDivider} />

                <FormField
                    wrapperClassName={styles.addressLine1}
                    field={form.fields.address_line1}
                />
                <FormField
                    wrapperClassName={styles.addressCity}
                    field={form.fields.address_city}
                />
                <FormField
                    wrapperClassName={styles.addressState}
                    field={form.fields.address_state}
                />
                <FormField
                    wrapperClassName={styles.addressZip}
                    field={form.fields.address_zip}
                />

                <div className={styles.sectionDivider} />

                <FormField
                    wrapperClassName={styles.message}
                    field={form.fields.message}
                />

                <FormField
                    wrapperClassName={styles.captcha}
                    field={form.fields.captcha}
                    hideLabel={true}
                />

                <div className={styles.actions}>
                    <button
                        type="submit"
                        className={styles.send}
                        disabled={isSubmitting}
                    >
                        {gettext("Send", context)}
                    </button>
                </div>
            </form>
        </section>
    );
};
